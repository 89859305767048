import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SummaryApi from '../common';

const CheckoutComplete = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const fetchOrderDetails = async () => {
    try {
      const response = await fetch(SummaryApi.getOrderDetails.url + `/${orderId}`, {
        method: SummaryApi.getOrderDetails.method,
        credentials: 'include',
        headers: {
          "content-type": 'application/json'
        },
      });
      const data = await response.json();
      
      if (data.success) {
        setOrderDetails(data.data);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Failed to fetch order details');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-lg">Loading order details...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-lg text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  const PaymentStatusBadge = ({ status }) => {
    const statusColors = {
      paid: 'bg-green-100 text-green-800',
      pending: 'bg-yellow-100 text-yellow-800',
      failed: 'bg-red-100 text-red-800'
    };

    return (
      <span className={`px-3 py-1 rounded-full text-sm font-medium ${statusColors[status] || 'bg-gray-100 text-gray-800'}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="text-center mb-8">
        <div className="w-16 h-16 mx-auto mb-4 text-green-500">✓</div>
        <h1 className="text-3xl font-bold mb-2">Order Confirmed!</h1>
        <p className="text-gray-600">Order #{orderId}</p>
      </div>

      <div className="space-y-6">
        {/* Order Status */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">Order Status</h2>
            <PaymentStatusBadge status={orderDetails.paymentStatus} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-600">Payment Reference</p>
              <p className="font-medium">{orderDetails.paymentReference}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Order Date</p>
              <p className="font-medium">
                {new Date(orderDetails.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>

        {/* Customer Details */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Customer Details</h2>
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <h3 className="font-medium mb-2">Contact Information</h3>
              <p>{orderDetails.shippingAddress.firstName} {orderDetails.shippingAddress.lastName}</p>
              <p>{orderDetails.shippingAddress.email}</p>
              <p>{orderDetails.shippingAddress.phone}</p>
            </div>
            <div>
              <h3 className="font-medium mb-2">Shipping Address</h3>
              <p>{orderDetails.shippingAddress.address}</p>
              <p>{orderDetails.shippingAddress.city}</p>
              <p>{orderDetails.shippingAddress.country}</p>
            </div>
          </div>
        </div>

        {/* Order Summary */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
          <div className="space-y-4">
            {orderDetails.items.map((item, index) => (
              <div key={index} className="flex justify-between items-center py-2 border-b">
                <div>
                  <p className="font-medium">{item.productId}</p>
                  <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
                </div>
                <p className="font-medium">${(item.price * item.quantity).toFixed(2)}</p>
              </div>
            ))}
            
            <div className="pt-4">
              <div className="flex justify-between mb-2">
                <p>Subtotal</p>
                <p>${(orderDetails.totalAmount - 2).toFixed(2)}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p>Shipping</p>
                <p>$2.00</p>
              </div>
              <div className="flex justify-between font-bold border-t pt-2">
                <p>Total</p>
                <p>${orderDetails.totalAmount.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button 
            onClick={() => window.print()} 
            className="bg-blue-600 text-white py-3 px-6 rounded hover:bg-blue-700"
          >
            Print Order Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutComplete;