import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { GrSearch } from 'react-icons/gr';
import { FaYinYang, FaShoppingCart } from 'react-icons/fa';
import Logo from './Logo';
import Search from './Search';
import Context from '../context'

const MobileHeader = ({ user, cartProductCount, onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const context = useContext(Context)

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    setIsSearchOpen(false);
  };

  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
    setIsMenuOpen(false);
  };

  return (
    <header className="h-24 lg:hidden sticky top-0 z-40 flex flex-col justify-center gap-1 bg-white">
      <div className="container mx-auto flex items-center px-4 justify-between">
        {/* <Link to="/">
          <Logo w={23} h={60} />
        </Link> */}

        <div className="flex items-center gap-4">
          <button
            className="text-2xl focus:outline-none"
            onClick={toggleSearch}
          >
            <GrSearch />
          </button>

          <button
            className="text-3xl cursor-pointer relative flex justify-center"
            onClick={toggleMenu}
          >
            {user?.profilePic ? (
              <img
                src={user.profilePic}
                className="w-10 h-10 rounded-full"
                alt={user.name}
              />
            ) : (
              <FaYinYang />
            )}
          </button>

          <Link to="/cart" className="text-2xl relative">
            <span>
              <FaShoppingCart />
            </span>
            <div className="bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3">
              <p className="text-sm">{context?.cartProductCount}</p>
            </div>
          </Link>
        </div>
      </div>

      {isMenuOpen && (
        <div className="absolute bg-white bottom-0 top-24 w-full p-4 shadow-lg">
          <nav>
            {user?.role === 'admin' && (
              <Link
                to="/admin-panel/all-products"
                className="block hover:bg-slate-100 p-2"
                onClick={toggleMenu}
              >
                Admin Panel
              </Link>
            )}
            <button
              className="block hover:bg-slate-100 p-2 w-full text-left"
              onClick={() => {
                toggleMenu();
                onLogout();
              }}
            >
              Logout
            </button>
          </nav>
        </div>
      )}

      {isSearchOpen && (
        <div className="absolute bg-white bottom-0 top-24 w-full p-4 shadow-lg">
          <Search />
        </div>
      )}
    </header>
  );
};

export default MobileHeader;