const { default: SummaryApi } = require("../common");

const fetchCategoryWiseProduct = async (category) => {
  try {
    if (!SummaryApi || !SummaryApi.categoryWiseProduct) {
      throw new Error('SummaryApi or categoryWiseProduct is undefined');
    }

    const { url, method } = SummaryApi.categoryWiseProduct;

    if (!url || !method) {
      throw new Error('URL or method is undefined in SummaryApi.categoryWiseProduct');
    }

    const response = await fetch(url, {
      method: method,
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        category: category
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const dataResponse = await response.json();
    return dataResponse;
  } catch (error) {
    console.error('Error in fetchCategoryWiseProduct:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export default fetchCategoryWiseProduct;