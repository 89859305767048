// Frontend: pages/Checkout.js
import React, { useState, useEffect } from 'react';
// import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import SummaryApi from '../common';


const Checkout = () => {
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: ''
   
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchCartData();
  }, []);

  const fetchCartData = async () => {
    try {
      const response = await fetch(SummaryApi.addToCartProductView.url, {
        method: SummaryApi.addToCartProductView.method,
        credentials: 'include',
        headers: {
          "content-type": 'application/json'
        },
      });
      
      const data = await response.json();
      if (data.success) {
        setCartData(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const calculateTotals = () => {
    const subtotal = cartData.reduce((prev, curr) => 
      prev + (curr.quantity * curr.productId.sellingPrice), 0);
    const shipping = 0.1;
    return { subtotal, shipping, total: subtotal + shipping };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Create order
      console.log('takutanga');
      const orderResponse = await fetch(SummaryApi.createOrder.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          items: cartData.map(item => ({
            productId: item.productId._id,
            quantity: item.quantity,
            price: item.productId.sellingPrice
          })),
          shippingAddress: formData,
          totalAmount: calculateTotals().total
        })
        
      });
      console.log('tutanga');
      const orderData = await orderResponse.json();
      // let orderID = orderData.data._id

      if (orderData.success) {
        // Redirect to payment page
        console.log('order yaita : ', orderData)
        // console.log('imwe order : ', orderData.data._id)
        navigate(`/payment/${orderData.data._id}`);
        // navigate(`/payment`);

      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Checkout</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Order Summary */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
          {cartData.map(item => (
            <div key={item._id} className="flex justify-between mb-4">
              <div>
                <p className="font-medium">{item.productId.productName}</p>
                <p className="text-gray-600">Quantity: {item.quantity}</p>
              </div>
              <p className="font-medium">
                ${(item.quantity * item.productId.sellingPrice).toFixed(2)}
              </p>
            </div>
          ))}
          <div className="border-t pt-4 mt-4">
            <div className="flex justify-between mb-2">
              <p>Subtotal</p>
              <p>${calculateTotals().subtotal.toFixed(2)}</p>
            </div>
            <div className="flex justify-between mb-2">
              <p>Shipping</p>
              <p>${calculateTotals().shipping.toFixed(2)}</p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Total</p>
              <p>${calculateTotals().total.toFixed(2)}</p>
            </div>
          </div>
        </div>

        {/* Checkout Form */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Shipping Information</h2>
          <form onSubmit={handleSubmit}>
            {/* Form fields from your original checkout, but with onChange handlers */}
            <div className="grid grid-cols-2 gap-4">
              {/* <div className="col-span-1">
                <label htmlFor="firstName" className="block mb-2">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  required
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="lastName" className="block mb-2">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  required
                />
              </div> */}
              <div className="col-span-1">
                <label htmlFor="email" className="block mb-2">Email</label>
                <input
                  type="text"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  required
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="phone" className="block mb-2">Phone</label>
                <input
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  required
                />
              </div>
              {/* <div className="col-span-2">
                <label htmlFor="address" className="block mb-2">Address</label>
                <input
                  type="text"
                  id="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  required
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="city" className="block mb-2">City</label>
                <input
                  type="text"
                  id="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  required
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="country" className="block mb-2">Country</label>
                <input
                  type="text"
                  id="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="w-full border rounded p-2"
                  required
                />
              </div> */}
              {/* Add other form fields similarly */}
            </div>
            
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-600 text-white py-3 rounded mt-6 hover:bg-blue-700"
            >
              {loading ? 'Processing...' : 'Proceed to Payment'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout