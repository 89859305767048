

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';

const Payment = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    initiatePayment();
  }, [orderId]);

  const initiatePayment = async () => {
    try {
      const response = await fetch(SummaryApi.initiatePayment.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ orderId })
      });

      const data = await response.json();
      console.log(data);

      if (data.success) {

        window.location.href = data.redirectUrl;
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentComplete = () => {

    navigate(`/checkout/complete/${orderId}`);
  };

  if (loading) {
    return <div className="text-center py-8">Initializing payment...</div>;
  }

  if (error) {
    // return <div className="text-center py-8 text-red-600">{error}</div>;
    return <button onClick={handlePaymentComplete} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Payment Refused
      </button>;
  }

  return (
    <div className="text-center py-8">
      Redirecting to payment gateway...
      <button onClick={handlePaymentComplete} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Payment Complete
      </button>
    </div>
  );
};

export default Payment;