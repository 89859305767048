import React, { useContext, useState } from 'react'
import Logo from './Logo'
import Search from './Search'
import MobileHeader from './Mobileheader'
import useMobile from '../hooks/useMobile';
import { GrSearch } from "react-icons/gr"
import { FaRegCircleUser } from "react-icons/fa6"
import { FaShoppingCart } from "react-icons/fa"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SummaryApi from '../common'
import { toast } from 'react-toastify'
import { setUserDetails } from '../store/userSlice'
import ROLE from '../common/role'
import Context from '../context'

const Header = () => {
  const dispatch = useDispatch()
  const [menuDisplay, setMenuDisplay] = useState(false)
  const context = useContext(Context)
  const [ isMobile ] = useMobile()
  const location = useLocation()
  const isSearchPage = location.pathname === "/search"
  const navigate = useNavigate()
  const user = useSelector(state => state?.user?.user)
  // const user = useSelector((state)=> state?.user)


  const handleLogout = async () => {
    try {
      const fetchData = await fetch(SummaryApi.logout_user.url, {
        method: SummaryApi.logout_user.method,
        credentials: 'include'
      })

      const data = await fetchData.json()

      if (data.success) {
        toast.success(data.message)
        dispatch(setUserDetails(null))
        navigate("/")
      }

      if (data.error) {
        toast.error(data.message)
      }
    } catch (error) {
      toast.error('Logout failed')
    }
  }

 

  const UserMenu = () => (
    user?._id && (
      <div className='text-3xl cursor-pointer relative flex justify-center' onClick={() => setMenuDisplay(prev => !prev)}>
        {user?.profilePic ? (
          <img src={user.profilePic} className='w-10 h-10 rounded-full' alt={user.name} />
        ) : (
          <FaRegCircleUser />
        )}
      </div>
    )
  )

  const DropdownMenu = () => (
    menuDisplay && (
      <div className='absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded'>
        <nav>
          {user?.role === ROLE.ADMIN && (
            <Link 
              to="/admin-panel/all-products" 
              className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' 
              onClick={() => setMenuDisplay(prev => !prev)}
            >
              Admin Panel
            </Link>
          )}
        </nav>
      </div>
    )
  )

  const CartIcon = () => (
    user?._id && (
      <Link to="/cart" className='text-2xl relative'>
        <span><FaShoppingCart/></span>
        <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
          <p className='text-sm'>{context?.cartProductCount}</p>
        </div>
      </Link>
    )
  )

  const AuthButton = () => (
    user?._id ? (
      <button 
        onClick={handleLogout} 
        className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700'
      >
        Logout
      </button>
    ) : (
      <Link 
        to="/login" 
        className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700'
      >
        Login
      </Link>
    )
  )

  return (
    <header className='h-24 lg:h-20 lg:shadow-md sticky top-0 z-40 flex flex-col justify-center gap-1 bg-white'>
      <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        

       

{
            !(isSearchPage && isMobile) && (
                <div className='container mx-auto flex items-center px-2 justify-between'>
                             <div className='hidden lg:block'>
                                  <Link to="/">
                                    <Logo w={213} h={60}/>
                                  </Link>
                                </div>
                                <div className=' lg:block'>
                                    <MobileHeader/>
                                </div>

                                {/**Search */}
                                <div className='hidden lg:block'>
                                    <Search/>
                                </div>

                                <div className='flex items-center gap-7'>
                                <div className='relative flex justify-center'>
                                  <UserMenu />
                                  <DropdownMenu />
                                </div>
                                
                                <CartIcon />
                                <AuthButton />
                              </div>


                </div>
            )
        }


        
        {/* <div className='container mx-auto px-2 lg:hidden'>
            <Search/>
        </div> */}

       
      </div>
    </header>
  )
}

export default Header