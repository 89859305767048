import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaStar, FaStarHalf } from "react-icons/fa";
import SummaryApi from '../common';
import displayUSDCurrency from '../helpers/displayCurrency';
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import addToCart from '../helpers/addToCart';
import Context from '../context';

// Custom Alert Component
const ErrorAlert = ({ message }) => (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-4" role="alert">
    <span className="block sm:inline">{message}</span>
  </div>
);

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: ""
  })
  const params = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const productImageListLoading = new Array(4).fill(null);
  const [activeImage, setActiveImage] = useState("");
  const [zoomImage, setZoomImage] = useState(false);
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 });
  const { fetchUserAddToCart } = useContext(Context);
 
  const navigate = useNavigate();
  
  

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(SummaryApi.productDetails.url, {
        method: SummaryApi.productDetails.method,
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          productId: params?.id
        })
      });
      setLoading(false)
      if (!response.ok) {
        throw new Error('Failed to fetch product details');
      }

      const dataResponse = await response.json();
      
      if (!dataResponse?.data) {
        throw new Error('No product data received');
      }

      setData(dataResponse.data);
      setActiveImage(dataResponse.data?.productImage?.[0] || "");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      fetchProductDetails();
    }
  }, [params]);

  const handleMouseEnterProduct = useCallback((imageURL) => {
    setActiveImage(imageURL);
  }, []);

  const handleZoomImage = useCallback((e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    
    setZoomImage(true);
    setZoomImageCoordinate({ x, y });
  }, []);

  const handleLeaveImageZoom = useCallback(() => {
    setZoomImage(false);
  }, []);

  const handleAddToCart = async (e, id) => {
    try {
      await addToCart(e, id);
      await fetchUserAddToCart();
    } catch (err) {
      console.error('Failed to add to cart:', err);
      setError('Failed to add item to cart. Please try again.');
    }
  };

  const handleBuyProduct = async (e, id) => {
    try {
      await addToCart(e, id);
      await fetchUserAddToCart();
      navigate("/cart");
    } catch (err) {
      console.error('Failed to process buy action:', err);
      setError('Failed to process purchase. Please try again.');
    }
  };

  if (error) {
    return <ErrorAlert message={error} />;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="min-h-[200px] flex flex-col lg:flex-row gap-4">
        {/* Product Image Section */}
        <div className="h-96 flex flex-col lg:flex-row-reverse gap-4">
          <div className="h-[300px] w-[300px] lg:h-96 lg:w-96 bg-slate-200 relative p-2">
            {activeImage && (
              <img 
                src={activeImage} 
                alt={data.productName}
                className="h-full w-full object-scale-down mix-blend-multiply" 
                onMouseMove={handleZoomImage} 
                onMouseLeave={handleLeaveImageZoom}
              />
            )}

            {zoomImage && (
              <div className="hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0">
                <div
                  className="w-full h-full min-h-[400px] min-w-[500px] mix-blend-multiply scale-150"
                  style={{
                    background: `url(${activeImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`
                  }}
                />
              </div>
            )}
          </div>

          <div className="h-full">
            {loading ? (
              <div className="flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full">
                {productImageListLoading.map((_, index) => (
                  <div 
                    key={`loadingImage${index}`}
                    className="h-20 w-20 bg-slate-200 rounded animate-pulse" 
                  />
                ))}
              </div>
            ) : (
              <div className="flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full">
                {data?.productImage?.map((imgURL) => (
                  <div 
                    key={imgURL}
                    className="h-20 w-20 bg-slate-200 rounded p-1"
                  >
                    <img 
                      src={imgURL} 
                      alt={`${data.productName} thumbnail`}
                      className="w-full h-full object-scale-down mix-blend-multiply cursor-pointer" 
                      onMouseEnter={() => handleMouseEnterProduct(imgURL)}
                      onClick={() => handleMouseEnterProduct(imgURL)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Product Details Section */}
        {loading ? (
          <div className="grid gap-1 w-full">
            {[...Array(7)].map((_, i) => (
              <div 
                key={`skeleton${i}`}
                className="h-6 lg:h-8 bg-slate-200 animate-pulse rounded-full w-full"
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            {data.brandName && (
              <p className="bg-red-200 text-red-600 px-2 rounded-full inline-block w-fit">
                {data.brandName}
              </p>
            )}
            <h2 className="text-2xl lg:text-4xl font-medium">{data.productName}</h2>
            {data.category && (
              <p className="capitalize text-slate-400">{data.category}</p>
            )}

            <div className="text-red-600 flex items-center gap-1">
              {[...Array(4)].map((_, i) => (
                <FaStar key={`star${i}`} />
              ))}
              <FaStarHalf />
            </div>

            <div className="flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1">
              <p className="text-red-600">
                {displayUSDCurrency(data.sellingPrice || "")}
              </p>
              <p className="text-slate-400 line-through">
                {displayUSDCurrency(data.price || "")}
              </p>
            </div>

            <div className="flex items-center gap-3 my-2">
              <button 
                className="border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white"
                onClick={(e) => handleBuyProduct(e, data._id)}
              >
                Buy
              </button>
              <button 
                className="border-2 border-red-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-red-600 hover:text-red-600 hover:bg-white"
                onClick={(e) => handleAddToCart(e, data._id)}
              >
                Add To Cart
              </button>
            </div>

            {data.description && (
              <div>
                <p className="text-slate-600 font-medium my-1">Description:</p>
                <p>{data.description}</p>
              </div>
            )}
          </div>
        )}
      </div>

      {data.category && (
        <CategroyWiseProductDisplay 
          category={data.category} 
          heading="Recommended Product"
        />
      )}
    </div>
  );
};

export default ProductDetails;