
const productCategory = [
    { id : 1, label : "Groceries & Beverages", value : "Groceries & Beverages"},
    { id : 2, label : "Home Appliances", value : "Home Appliances"},
    { id : 3, label : "Mobile Phones", value : "Mobile Phones"},
    { id : 4, label : "Computers & Accessories", value : "Computers & Accessories"},
    { id : 5, label : "Automotive", value : "Automotive"},
    { id : 6, label : "Health, Beauty & Personal Care", value : "Health, Beauty & Personal Care"},
    { id : 7, label : "Party & Occassions", value : "Party & Occassions"},
    { id : 8, label : "Fruits & Vegetables", value : "Fruits & Vegetables"},
    { id : 9, label : "Office, Stationery & Books", value : "Office, Stationery & Books"},
    // { id : 10,label : "Tech Team", value : "All Repairs"},
    // { id : 11,label : "Insect Extermonators", value : "All Crawling Insects"}
    // { id : 9, label : "Speakers", value : "speakers"},
    // { id : 10, label : "Trimmers", value : "trimmers"},
    // { id : 11, label : "Televisions", value : "televisions"},
    // { id : 12, label : "Watches", value : "watches"},
]


export default productCategory
