import React from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div>
      <CategoryList/>
      <BannerProduct/>

      <HorizontalCardProduct category={"Groceries & Beverages"} heading={"Top's Groceries & Beverages"}/>
      <HorizontalCardProduct category={"Groceries & Beverages"} heading={"Popular's food stuff and drinks"}/>

      <VerticalCardProduct category={"Groceries & Beverages"} heading={"Groceries & Beverages"}/>
      <VerticalCardProduct category={"Home Appliances"} heading={"Home Appliances"}/>
      <VerticalCardProduct category={"Mobile Phones"} heading={"Mobile Phones"}/>
      <VerticalCardProduct category={"Computers & Accessories"} heading={"Computer & Accessories"}/>
      <VerticalCardProduct category={"Office, Stationery & Books"} heading={"Office, Stationery & Books"}/>
      <VerticalCardProduct category={"Automotive"} heading={"Automotive"}/>
      <VerticalCardProduct category={"Health ,Beauty & Personal Care"} heading={"Health ,Beauty & Personal Care"}/>
      <VerticalCardProduct category={"Party & Occasions"} heading={"Party & Occasions"}/>
      {/* <VerticalCardProduct category={"Tech Team"} heading={"All Repairs"}/>
      <VerticalCardProduct category={"Insect Extermonators"} heading={"All Crawling Insects"}/> */}
    </div>
  )
}

export default Home